import React from 'react'
import PropTypes from 'prop-types'
import Highlighter from "react-highlight-words"

const Pricing = ({ tests, query }) => (
  <div>
    {tests.map(test => (
      <div key={test.name} className="columns is-multiline is-mobile is-vcentered price-row">
        <div className="column is-8">
          <p className="has-text-left">
            {!!query ?
              <Highlighter
                searchWords={query.split(" ")}
                autoEscape={true}
                textToHighlight={test.name}
                highlightStyle={{
                  backgroundColor: "#009fb2",
                  color: "white"
                }}
              />
              : test.name}
          </p>
        </div>
        <div className="column is-4">
          <p className="has-text-left">
            {test.cost}
          </p>
        </div>
      </div>
    ))}
  </div>
)

Pricing.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    cost: PropTypes.string,
  })),
}

export default Pricing
